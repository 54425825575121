import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function PiplineStageChart({ filteredPipline }) {
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const filterpipelineData = filteredPipline.filter((e, i) => i !== 0)
    const filterData = filterpipelineData.map((item) => {
        const data = item.data.filter((data) => data.level === 0)
        return {
            metro: item.metro,
            data
        }
    })

    const createdDriver = filterData.map(({ data }) => data.filter((data) => data.label === "Created Driver")).flat()
    const onboardingStart = filterData.map(({ data }) => data.filter((data) => data.label === "Onboarding Start")).flat()
    const onboardingComplete = filterData.map(({ data }) => data.filter((data) => data.label === "Onboarding Complete")).flat()
    const approvedDriver = filterData.map(({ data }) => data.filter((data) => data.label === "Approved Driver")).flat()
    const backgroundCheckStart = filterData.map(({ data }) => data.filter((data) => data.label === "Background Check Start")).flat()
    const backgroundCheckComplete = filterData.map(({ data }) => data.filter((data) => data.label === "Background Check Complete")).flat()
    const oneShift = filterData.map(({ data }) => data.filter((data) => data.label === "Completed 1 Shift")).flat()
    const twoToFourShifts = filterData.map(({ data }) => data.filter((data) => data.label === "Completed 2 To 4 Shifts")).flat()
    const fivePlusShifts = filterData.map(({ data }) => data.filter((data) => data.label === "Completed 5 Plus Shifts")).flat()

    const labels = filterpipelineData.map((item) => item.metro)
    const data = {
        labels,
        datasets: [
            {
                label: 'Completed 5 Plus Shifts',
                data: fivePlusShifts.map(item => item.counts),
                backgroundColor: 'rgb(56, 86, 35)',
            },
            {
                label: 'Completed 2 To 4 Shifts',
                data: twoToFourShifts.map(item => item.counts),
                backgroundColor: 'rgb(84, 130, 53)',
            },
            {
                label: 'Completed 1 Shift',
                data: oneShift.map(item => item.counts),
                backgroundColor: 'rgb(169, 209, 142)',
            },
            // {
            //     label: 'Background Check Complete',
            //     data: backgroundCheckComplete.map(item => item.counts),
            //     backgroundColor: 'rgb(249, 192, 3)',
            // },
            // {
            //     label: 'Background Check Start',
            //     data: backgroundCheckStart.map(item => item.counts),
            //     backgroundColor: 'rgb(254, 255, 1)',
            // },
            {
                label: 'Approved Driver',
                data: approvedDriver.map(item => item.counts),
                backgroundColor: 'rgb(8, 32, 96)',
            },
            {
                label: 'Onboarding Complete',
                data: onboardingComplete.map(item => item.counts),
                backgroundColor: 'rgb(38, 68, 120)',
            },
            {
                label: 'Onboarding Start',
                data: onboardingStart.map(item => item.counts),
                backgroundColor: 'rgb(197, 90, 16)',
            },
            {
                label: 'Created Driver',
                data: createdDriver.map(item => item.counts),
                backgroundColor: 'rgb(244, 1, 3)',
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Pipeline and Conversion Rates',
                font: {
                    size: windowSize.innerWidth >= 1200 ? 20 : 15,
                }
            },
            legend: {
                display: true,
                position:"right",
                labels: {
                    boxWidth: windowSize.innerWidth >= 1200 ? 12 : 5,
                    boxHeight: windowSize.innerWidth >= 1200 ? 12 : 5,
                    font: {
                        size: windowSize.innerWidth >= 1200 ? 12 : 7
                    }
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: windowSize.innerWidth >= 1200 ? 11 : 7,
                    }
                },
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 20,
                },
            },
        },
    };
    return (
        <>
            <div>
                <Bar data={data} options={options} />
            </div>
        </>
    )
}

export default PiplineStageChart
